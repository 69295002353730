// @flow

import moment from 'moment'
import colors from '@/../src/styles/colors.scss'
import { Strings } from '@/constants'
import createTooltip from './tooltip'

const MILLION = 1e6
// duplicated /templates/pdf.ejs:126
const primaryDatasetGradient = (canvas?: HTMLCanvasElement) => {
  if (canvas) {
    const ctx = canvas.getContext('2d')
    if (ctx) {
      const gradient = ctx.createLinearGradient(0, 200, 0, 0)
      gradient.addColorStop(0, colors.gradientWhite)
      gradient.addColorStop(1, colors.gradientGold)
      return gradient
    }
  }
  return ''
}
// duplicated /templates/pdf.ejs:180
const secondaryDatasetGradient = (canvas?: HTMLCanvasElement) => {
  if (canvas) {
    const ctx = canvas.getContext('2d')
    if (ctx) {
      const gradient = ctx.createLinearGradient(0, 200, 0, 0)
      gradient.addColorStop(0, colors.gradientWhite)
      gradient.addColorStop(1, colors.gradientBlue)
      return gradient
    }
  }
  return ''
}

const tickStyles = {
  fontFamily: 'OpenSans, sans-serif',
  fontSize: 12,
  fontWeight: 300,
  padding: 10,
}

const SCALE_OPTIONS = {
  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
          lineWidth: 2,
          tickMarkLength: 5,
          color: colors.grey,
          drawBorder: false,
        },
        ticks: {
          callback: (date: string) => moment(date, 'MMMM, YYYY').format('M/YYYY'),
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 7,
          ...tickStyles,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          zeroLineWidth: 1,
          zeroLineBorderDash: [5, 3],
          borderDash: [5, 3],
          color: colors.black,
          zeroLineColor: colors.black,
          drawBorder: false,
        },
        scaleLabel: {
          display: true,
          fontColor: colors.grey,
          fontSize: 12,
          labelString: Strings.chart.inMillions,
          padding: { top: 0, bottom: 20 },
        },
        ticks: {
          beginAtZero: true,
          callback: (value: number, index: number) => {
            let tick = value / MILLION
            if (index === 0) {
              tick = `$${tick}`
            }
            return tick
          },
          maxTicksLimit: 7,
          ...tickStyles,
        },
      },
    ],
  },
}
// duplicated @/components/constants:145
export const CHART_OPTIONS: Object = (chartRef: any) => ({
  ...SCALE_OPTIONS,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
    mode: 'x',
    intersect: false,
    custom: createTooltip(chartRef),
    position: 'nearest',

    callbacks: {
      title: tooltipItem => tooltipItem[0].label || '',
      labelColor: tooltipItem =>
        tooltipItem.datasetIndex === 0
          ? { borderColor: colors.white, backgroundColor: colors.gold }
          : { borderColor: colors.white, backgroundColor: colors.blue },
      label: (tooltipItem, data) => {
        const { index, datasetIndex } = tooltipItem
        const label =
          datasetIndex === 0 ? data.datasets[0].data[index] : data.datasets[1].data[index]

        return label
      },
    },
  },
})
// duplicated /templates/pdf.ejs:115
export const CHART_DEFAULTS = {
  borderCapStyle: 'round',
  fill: true,
  lineTension: 0.4,
  pointHitRadius: 8,
  pointHoverBorderWidth: 5,
  pointHoverRadius: 10,
  pointRadius: 0,
  pointStyle: 'rect',
}
// duplicated /templates/pdf.ejs:152
export const primaryDatasetOptions = (canvas?: HTMLCanvasElement) => ({
  ...CHART_DEFAULTS,
  borderColor: colors.gold,
  backgroundColor: primaryDatasetGradient(canvas),
  pointHoverBackgroundColor: colors.gold,
  pointHoverBorderColor: colors.pointBorderGold,
})
// duplicated /templates/pdf.ejs:162
export const secondaryDatasetOptions = (canvas?: HTMLCanvasElement) => ({
  ...CHART_DEFAULTS,
  borderColor: colors.blue,
  backgroundColor: secondaryDatasetGradient(canvas),
  pointHoverBackgroundColor: colors.blue,
  pointHoverBorderColor: colors.pointBorderBlue,
})

export const yLinePlugin = {
  afterDatasetsDraw: (chart: any) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0]
      const { ctx } = chart.chart
      const y_axis = chart.scales['y-axis-0']
      const { y, x } = activePoint.tooltipPosition()
      const bottomY = y_axis.bottom

      // draw line
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, y)
      ctx.lineTo(x, bottomY + 1)
      ctx.setLineDash([2, 3])
      ctx.lineWidth = 1
      ctx.strokeStyle = '#DC9E1D'
      ctx.stroke()
      ctx.restore()
    }
  },
}

export const getChartLabels = (statements: { [string]: PPPolicyType }) =>
  (Object.keys(statements): any).sort((a, b) => {
    if (a === b) return 0
    return moment(b, 'MMMM, YYYY').isAfter(moment(a, 'MMMM, YYYY')) ? -1 : 1
  })

export const getPrimaryDataset = (
  labels: Array<string>,
  statementsByEndDate: { [string]: PPPolicyType },
) => ({
  label: Strings.accountValue,
  data: labels.map<*>((month: string) =>
    statementsByEndDate[month].reduce((acc, cur) => acc + cur.policyGrossAccountValue, 0),
  ),
})

export const getSecondaryDataset = (
  labels: Array<string>,
  statementsByEndDate: { [string]: PPPolicyType },
) => ({
  label: Strings.costBasis,
  data: labels.map<*>((month: string) =>
    statementsByEndDate[month].reduce((acc, cur) => acc + cur.costBasis, 0),
  ),
})
